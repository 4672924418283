/* CSS MAIN STYLES ===============================================================================*/
/* cREATED BY: Christian David Sierra Martinez*/
/* Improvements/pending: --- */

/* Scrrollbar styles =============================================================================*/
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;

}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);

}

/* Define variable properties reachable for the whole app =======================================*/
:root,
[data-bs-theme="light"] {
  --bs-blue: #5e50f9;
  --bs-indigo: #6610f2;
  --bs-purple: #6a008a;
  --bs-pink: #e91e63;
  --bs-red: #f96868;
  --bs-orange: #f2a654;
  --bs-yellow: #f6e84e;
  --bs-green: #46c35f;
  --bs-teal: #58d8a3;
  --bs-cyan: #57c7d4;
  --bs-white: #ffffff;
  --bs-gray: #434a54;
  --bs-gray-light: #aab2bd;
  --bs-gray-lighter: #e8eff4;
  --bs-gray-lightest: #e6e9ed;
  --bs-gray-dark: #0f1531;
  --bs-black: #000000;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #3c37f1;
  --bs-secondary: #1998ff;
  --bs-success: #00d663;
  --bs-info: #00b3ff;
  --bs-warning: #ffe671;
  --bs-danger: #ff696d;
  --bs-light: #506690;
  --bs-dark: #000000;
  --bs-primary-rgb: 60, 55, 241;
  --bs-secondary-rgb: 25, 152, 255;
  --bs-success-rgb: 0, 214, 99;
  --bs-info-rgb: 0, 179, 255;
  --bs-warning-rgb: 255, 230, 113;
  --bs-danger-rgb: 255, 105, 109;
  --bs-light-rgb: 80, 102, 144;
  --bs-dark-rgb: 0, 0, 0;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #6c7293;
  --bs-body-color-rgb: 108, 114, 147;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #f1efef;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;

  /* Main Background Styles */
  --main-bg-1: #9ed48e;
  --scrollbar-color:#9ed48e;
  --main-bg-button:#70be4a;
  --duotone-svgs-color-1:#9ed48e;
  --duotone-svgs-color-2:#9ed48e;
  --text-contrast-color:#226c3b;

  /* Dark colors (mainly text color and background for cards) */
  --color-1: #444746;
  --color-2: #555755;
  --color-3: #768076;
  --color-4: #181818;

  /* Glassy colors for backgrounds mainly */
  --color-glass-1: rgba(49, 65, 40, 0.3);
  --color-glass-2: rgba(73, 97, 59, 0.3);
  --color-glass-3: rgba(49, 65, 40, 0.8);

  --light-grey-bg: rgba(255, 255, 255, 0.2);
  /* Green Palette number 1 */
  --color-g-1: #d2e9c7;
  --color-g-2: #9ed48e;
  --color-g-3: #70be4a;
  --color-g-4: #37b977;

  /* Green Palette number 2 */
  --color-g-5: #226c3b;
  --color-g-6: #2d8f4e;
  --color-g-7: #38b261;
  --color-g-8: #50c878;
  --color-g-9: #73d393;
  --color-g-10: #96deae;
  --color-g-11: #b9e9c9;

  /* Colorfull complementary colors */
  --color-cl-pink: pink;
  --color-cl-orange: rgb(255, 213, 135);
  --color-cl-yellow: rgb(255, 255, 162);
  --color-cl-blue: rgb(148, 148, 255);

  --color-cd-pink: rgb(248, 153, 169);
  --color-cd-orange: rgb(255, 186, 57);
  --color-cd-yellow: rgb(255, 255, 118);
  --color-cd-blue: rgb(84, 84, 255);

  /* Other properties */
  --font-size-1: 1rem;
  --font-size-2: 1.2rem;
  --font-size-3: 1.8rem;
  --font-size-4: 0.9rem;

  --fs-paragraph-1: clamp(1.1rem, 2.5vw, 1.1rem) !important;
  --fs-paragraph-0: clamp(1.1rem, 2.5vw, 1rem) !important;

  --fs-small-0: clamp(1.1rem, 2.5vw, 1.1rem) !important;
  --fs-small-1: clamp(1.1rem, 2.5vw, 1rem) !important;

  --shadow-1: rgb(0 0 0 / 35%) 0px 8px 24px;
  --shadow-2: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  --border-radius-1: 0.25rem;
  --border-radius-2: 0.5rem;

  --font-family-1: "Poppins", sans-serif;
  --animation-1: 200ms;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

/* Body and html general styling and normalization ============================================= */
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100dvh;
  height: 100dvh;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-black);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input,
button {
  font-family: var(--font-family-1);
}

button:disabled {
  opacity: 0.5;
}

::selection {
  color: white;
  background: var(--color-g-4);
}

.form-select{
  border-radius: 0;
}

/* ANIMATIONS ==================================================================================*/
/* --- */

/* MEDIA QUERIES ===============================================================================*/
/* ---- */
@media only screen and (max-width: 800px) {
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}

/* Template banner ====================================================================================*/
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.termsModalClass{
  background-color: transparent;
  padding: 0px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid var(--bs-black);
}

.row{
  --bs-gutter-x:0
}

.container{
  padding-right: 0;
  padding-left: 0;
}

.NgxEditor__Content {
  padding: 8px;
  white-space: pre-wrap;
  outline: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
  min-height: 350px;
}

.NgxEditor__Content--Disabled {
    -webkit-user-select: auto !important; 
    user-select:auto !important; 
    pointer-events:auto !important;  
}

.NgxEditor{
  background: var(--bs-gray-200) !important;
  color: var(--ngx-editor-text-color) !important;
  background-clip: padding-box !important;
  border-radius: var(--ngx-editor-border-radius) !important;
  border: none !important;
  position: relative !important;
}


.mat-form-field-disabled
  { 
    background-color: var(--bs-gray-200);
  } 